import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/apiService';
import queryString from 'query-string';

/*
 * Places
 */
const fetchPlaces = createAsyncThunk('place/fetchPlaces', async query => {
  return api.get(`/profile/places?${queryString.stringify(query)}`);
});

const fetchAllPlaces = createAsyncThunk('place/fetchAllPlaces', async query => {
  return api.get(`/profile/places/all?${queryString.stringify(query)}`);
});

/*
 * Place
 */
const addPlace = createAsyncThunk('place/addPlace', async data => {
  return api.post(`/places`, data);
});

const fetchPlace = createAsyncThunk('place/fetchPlace', async data => {
  return api.post(`/places/${data.id}`);
});

const updatePlace = createAsyncThunk('place/updatePlace', async data => {
  return api.put(`/places/${data.id}`, data);
});

const updatePlaceSuperAdmin = createAsyncThunk('place/updatePlaceSuperAdmin', async data => {
  return api.put(`/places/${data.id}/super`, data);
});

const updatePlaceDonates = createAsyncThunk('place/updatePlace', async data => {
  return api.put(`/places/${data.id}/donates`, data);
});

const updatePlaceBusyHours = createAsyncThunk('place/updatePlaceBusyHours', async data => {
  return api.put(`/places/${data.id}/busyHours`, data);
});

const updatePlaceConnections = createAsyncThunk('place/updatePlaceConnections', async data => {
  return api.put(`/places/${data.id}/connections`, data);
});

const deletePlace = createAsyncThunk('place/deletePlace', async data => {
  return api.delete(`/places/${data.id}`);
});

/*
 * Open hours
 */
const updateAvatar = createAsyncThunk('place/updateAvatar', async data => {
  return api.put(`/places/${data.placeId}/avatar`, data.file, {
    'Content-Type': 'multipart/form-data',
  });
});

/*
 * Open hours
 */
const fetchOpenHour = createAsyncThunk('place/fetchOpenHour', async data => {
  return api.get(`/places/${data.placeId}/openHours`);
});

const addOpenHour = createAsyncThunk('place/addOpenHour', async data => {
  return api.post(`/places/${data.placeId}/openHours`, data);
});

const updateOpenHour = createAsyncThunk('place/updateOpenHour', async data => {
  return api.put(`/places/${data.placeId}/openHours`, data.openHours);
});

/*
 * Photos
 */
const fetchPhotos = createAsyncThunk('place/fetchPhotos', async data => {
  return api.get(`/places/${data.placeId}/photos`);
});

const addPhoto = createAsyncThunk('place/addPhoto', async data => {
  return api.post(`/places/${data.placeId}/photos`, data.formData, {
    'Content-Type': 'multipart/form-data',
  });
});

const fetchPaidServices = createAsyncThunk('place/getPaidServices', async (placeId) => {
  return api.get(`/places/${placeId}/paidServices`);
});

const savePaidServices = createAsyncThunk('place/savePaidServices', async ({ placeId, ...data }) => {
  return api.post(`/places/${placeId}/paidServices`, data);
});


const addPaidServicesPhoto = createAsyncThunk('place/addPaidServicesPhoto', async ({ placeId, formData }) => {
  return api.post(`/places/${placeId}/paidServices/image`, formData, {
    'Content-Type': 'multipart/form-data',
  });
});

const deletePaidServicesPhoto = createAsyncThunk('place/deletePaidServicesPhoto', async data => {
  return api.delete(`/places/${data.placeId}/paidServices/${data.photo}`);
});

const deletePhoto = createAsyncThunk('place/deletePhoto', async data => {
  return api.delete(`/places/${data.placeId}/photos/${data.id}`);
});

/*
 * Contacts
 */
const fetchContacts = createAsyncThunk('place/fetchContacts', async data => {
  return api.get(`/places/${data.placeId}/contacts`);
});

const addContact = createAsyncThunk('place/addContact', async data => {
  return api.post(`/places/${data.placeId}/contacts`, data);
});

const updateContact = createAsyncThunk('place/updateContact', async data => {
  return api.put(`/places/${data.placeId}/contacts/${data.id}`, data);
});

const deleteContact = createAsyncThunk('place/deleteContact', async data => {
  return api.delete(`/places/${data.placeId}/contacts/${data.id}`, data);
});

/*
 * Managers
 */
const fetchManagers = createAsyncThunk('place/fetchManagers', async data => {
  return api.get(`/places/${data.placeId}/managers`);
});

const addManager = createAsyncThunk('place/addManager', async data => {
  return api.post(`/places/${data.placeId}/managers`, data);
});

const updateManager = createAsyncThunk('place/updateManager', async data => {
  return api.put(`/places/${data.placeId}/managers/${data.userId}`, data);
});

const deleteManager = createAsyncThunk('place/deleteManager', async data => {
  return api.delete(`/places/${data.placeId}/managers/${data.userId}`, data);
});

const fetchPlaceStats = createAsyncThunk('place/fetchPlaceStats', async data => {
  return api.get(`/places/${data.placeId}/stats?from=${data.from}&to=${data.to}`);
});

const fetchPlaceClient = createAsyncThunk('place/fetchPlaceClient', async ({ clientId, placeId, ...query }) => {
  return api.get(`/places/${placeId}/clients/${clientId}?${queryString.stringify(query)}`);
});

const fetchPlaceStatsVisits = createAsyncThunk('place/fetchPlaceStatsVisits', async ({ placeId }) => {
  return api.get(`/places/${placeId}/statsVisits`);
});

const fetchReviews = createAsyncThunk('place/fetchReviews', async ({ placeId, ...query }) => {
  return api.get(`/places/${placeId}/reviews?${queryString.stringify(query)}`);
});
const fetchReviewStats = createAsyncThunk('place/fetchReviewStats', async ({ placeId }) => {
  return api.get(`/places/${placeId}/reviews/stats`);
});
const fetchReviewGoogleStats = createAsyncThunk('place/fetchReviewGoogleStats', async ({ placeId }) => {
  return api.get(`/places/${placeId}/google/reviews`);
});

const fetchDonations = createAsyncThunk('place/fetchDonations', async () => {
  return api.get(`/donates`);
});

const fetchClientHistory = createAsyncThunk('user/reservationHistory', async ({ placeId, clientId, all }) => {
  return api.get(`/places/${placeId}/reservations?userId=${clientId}${all ? '' : '&limit=4'}`);
});

const saveClosedDays = createAsyncThunk('place/saveClosedDays', async ({ placeId, days }) => {
  return api.put(`/places/${placeId}/closedDays`, days);
});
const saveReservationUi = createAsyncThunk('place/saveReservationUi', async ({ placeId, settings, styles }) => {
  return api.put(`/places/${placeId}/reservationUi`, { settings, styles });
});

const fetchCustomTags = createAsyncThunk('place/fetchCustomTags', async ({ placeId }) => {
  return api.get(`/places/${placeId}/tags`);
});
const saveCustomTags = createAsyncThunk('place/saveCustomTags', async ({ placeId, values }) => {
  return api.post(`/places/${placeId}/tags`, { variables: values });
});

const fetchDeposits = createAsyncThunk('place/fetchDeposits', async ({ placeId }) => {
  return api.get(`/places/${placeId}/deposits`);
});
const saveDeposit = createAsyncThunk('place/saveDeposit', async ({ placeId, deposit }) => {
  return api.post(`/places/${placeId}/deposits`, deposit);
});
const deleteDeposit = createAsyncThunk('place/deleteDeposit', async ({ placeId, depositId }) => {
  return api.delete(`/places/${placeId}/deposits/${depositId}`);
});
const deleteDepositType = createAsyncThunk('place/deleteDepositType', async ({ placeId, depositId, typeId }) => {
  return api.delete(`/places/${placeId}/deposits/${depositId}/types/${typeId}`);
});

export default {
  fetchAllPlaces,

  fetchPlaces,
  addPlace,
  fetchPlace,
  updatePlace,
  updatePlaceBusyHours,
  updatePlaceSuperAdmin,
  updatePlaceDonates,
  updatePlaceConnections,
  deletePlace,

  updateAvatar,

  fetchOpenHour,
  addOpenHour,
  updateOpenHour,

  fetchPhotos,
  addPhoto,
  deletePhoto,

  fetchContacts,
  addContact,
  updateContact,
  deleteContact,

  fetchManagers,
  addManager,
  updateManager,
  deleteManager,

  fetchPlaceStats,

  fetchPlaceClient,

  fetchReviews,
  fetchReviewStats,
  fetchReviewGoogleStats,

  fetchPlaceStatsVisits,

  fetchDonations,
  fetchClientHistory,

  saveClosedDays,

  saveReservationUi,

  fetchCustomTags,
  saveCustomTags,

  fetchDeposits,
  saveDeposit,
  deleteDeposit,
  deleteDepositType,

  fetchPaidServices,
  savePaidServices,
  addPaidServicesPhoto,
  deletePaidServicesPhoto,
};
