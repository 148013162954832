import React, { memo, useEffect } from 'react';
import { Form, withFormik } from 'formik';
import Modal from '@components/Modal/Modal';
import ModalActions from '@components/Modal/ModalActions';
import mova from 'mova';
import FormikTextInput from '@form/FormikTextInput';
import * as Yup from 'yup';
import Button from '@components/Button/Button';
import store from '@state/store';
import { reservationThunks } from 'state/ducks/reservation';

const t = mova.ns('components.Reservations');

const schema = Yup.object().shape({
  feedback: Yup.string().required(() => t('errors.required')),
});

const RejectReasonModal = ({ open, onClose, submitForm, setFieldValue, resetForm }) => {
  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [open, resetForm]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('rejectReasonTitle')}
      actions={<ModalActions onClose={onClose} submitForm={submitForm} submitTitle={t('confirm')} />}
    >
      <Form>
        <Button sx={{ my: 1 }} fullWidth color='primary' onClick={() => setFieldValue('feedback', t('rejectNoPlaces'))}>
          {t('rejectNoPlacesShort')}
        </Button>
        <FormikTextInput multiline sx={{ my: 1 }} fullWidth name='feedback' placeholder={t('rejectReason')} />
      </Form>
    </Modal>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: () => ({
      feedback: '',
    }),
    validationSchema: schema,
    handleSubmit: async (data, helpers) => {
      store.dispatch(
        reservationThunks.updateReservation({
          id: helpers.props.reservation.id,
          statusFrom: helpers.props.reservation.status,
          statusTo: 'REJECTED',
          callback: helpers.props.onClose,
          ...data,
        }),
      );
    },
  })(RejectReasonModal),
);
