import React, { memo } from 'react';
import ReactDatepicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Datepicker.scss';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Icon from '@components/Icon/Icon';
import styled from '@emotion/styled';
import uk from 'date-fns/locale/uk';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import { useSelector } from 'react-redux';
import userSelectors from '@state/ducks/user/selectors';
import localStorage from 'utils/localStorage';
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import { useTheme } from "@mui/material";
import CustomDatepickerInput from "./CustomDatepickerInput";

// toDO: add other locales in case of working outside Ukraine
registerLocale('ua', uk);
registerLocale('ru', ru);
registerLocale('en', en);
registerLocale('es', es);

const StyledDatepicker = styled(ReactDatepicker,
  { shouldForwardProp: prop => !['bold', 'showEndIcon'].includes(prop) })(({ theme, bold, fullWidth, showEndIcon }) => ({
  width: fullWidth ? '100%' : showEndIcon ? '164px' : '144px',
  padding: `7px ${showEndIcon ? '35px' : '15px'} 7px 41px`,
  borderRadius: theme.shape.borderRadiusSmall,
  lineHeight: '23px',
  outline: 'none',
  border: `1px solid ${theme.palette.typography.light}`,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: bold ? 'bold' : 'normal',
}));

const StyledIcon = styled(Icon)(() => ({
  position: 'absolute',
  top: '7px',
  left: '12px',
  pointerEvents: 'none',
}));

const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: '-1px',
  right: '2px',
}));

const Datepicker = (
  {
    bold,
    datepickerProps = {},
    inputProps= {},
    dateFormat = 'dd.MM.yyyy',
    showIcon = true,
    disabled,
    showToday = false,
    allowClear = false,
    icon = 'calendar',
    ...rest
  }
) => {
  const theme = useTheme();
  const user = useSelector(userSelectors.getCurrentUser());
  const currentLang = user?.lang || localStorage.get(localStorage.Keys.LANG);
  const otherDay = datepickerProps.selected &&
    moment(datepickerProps.selected).format('L') !== moment().format('L')
  const showTodayIcon = showToday && otherDay;
  const showClearIcon = allowClear && datepickerProps.selected;
  const setToday = () => {
    datepickerProps.onChange && datepickerProps.onChange(new Date());
  }

  return (
    <Box {...rest} sx={{ position: 'relative', ...(rest?.sx || {}) }}>
      <StyledDatepicker
        showEndIcon={showTodayIcon || showClearIcon}
        disabled={disabled}
        dateFormat={dateFormat}
        bold={bold}
        locale={currentLang}
        customInput={<CustomDatepickerInput {...inputProps} />}
        {...datepickerProps}
      />
      {showIcon && <StyledIcon type={icon} color='black' />}
      {showTodayIcon && (
        <StyledIconButton onClick={setToday}><Icon type='finish' color={theme.palette.primary.main} /></StyledIconButton>
      )}
      {showClearIcon && (
        <StyledIconButton sx={{ top: '4px !important' }} onClick={() => datepickerProps.onChange(null)}>
          <Icon type='close' color={theme.palette.primary.main} />
        </StyledIconButton>
      )}
    </Box>
  );
};

Datepicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  bold: PropTypes.bool,
  datepickerProps: PropTypes.object, // eslint-disable-line
};

export default memo(Datepicker);
