import React, { memo } from 'react';
import { Card as MaterialCard } from '@mui/material';
import styled from '@emotion/styled';

const StyledCard = styled(MaterialCard)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  boxShadow: theme.shadow,
}));

const Card = ({ children, sx = {}, ...rest }) => {
  return (
    <StyledCard sx={{ overflow: 'visible', ...sx }} {...rest}>{children}</StyledCard>
  );
};

export default memo(Card);
