import React, { memo } from 'react';
import { useField } from 'formik';
import Select from '@form/Select';
import { FormHelperText, useTheme } from '@mui/material';

const FormikSelect = ({ name, onChange, ...rest }) => {
  const theme = useTheme();
  const [{ onChange: formikOnChange, ...field }, state] = useField(name);

  const handleChange = (e) => {
    formikOnChange(e);
    onChange && onChange(e);
  };

  return (
    <>
      <Select {...field} {...rest} onChange={handleChange} sendEvent />
      {!!state.error && state.touched && (
        <FormHelperText sx={{ mt: -0.5, color: theme.palette.secondary.dark }}>
          {!!state.error && state.touched && state.error}
        </FormHelperText>
      )}
    </>
  );
};

export default memo(FormikSelect);
