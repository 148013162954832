import React, { memo } from 'react';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';

const StyledDialog = styled(Dialog)(({ theme, background }) => ({
  '.MuiDialog-paper': {
    width: `calc(100% - ${theme.spacing(2)})`,
    margin: theme.spacing(2),
    backgroundColor: background,
    [theme.breakpoints.up('xssm')]: {
      width: `calc(100% - ${theme.spacing(4)})`,
      margin: theme.spacing(4),
    }
  }
}));

const StyledActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.typography.background,
  [theme.breakpoints.up('xssm')]: {
    padding: theme.spacing(3),
  }
}));

const Modal = ({ open, onClose, title, children, actions, maxWidth = 'xs', fullWidth = false, background = 'white', ...rest }) => {
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('xssm'));

  return (
    <StyledDialog
      onClose={onClose}
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      background={background}
      disableAutoFocus
      {...rest}
    >
      {
        title &&
        <DialogTitle sx={{ p: mobileView ? 2 : 3 }}>
          <Typography component='span' variant='h3'>{title}</Typography>
        </DialogTitle>
      }
      <DialogContent dividers sx={{ p: mobileView ? 2 : 3 }}>{children}</DialogContent>
      {actions && <StyledActions>{actions}</StyledActions>}
    </StyledDialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  actions: PropTypes.node,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool
};

export default memo(Modal);
