import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { placeActions, placeSelectors, placeThunks } from '../../state/ducks/place';
import { billingSelectors, billingThunks } from '../../state/ducks/billing';
import Loader from '../components/Loader/Loader';
import { userSelectors } from '../../state/ducks/user';
import localStorage from '../../utils/localStorage';
import { reservationThunks } from '../../state/ducks/reservation';
import { hallThunks } from '../../state/ducks/hall';
import config from 'config';
import { dishThunks } from "../../state/ducks/dish";

const { defaultLang } = config;

const AppDataContainer = ({ children }) => {
  const dispatch = useDispatch();
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const billingsStats = useSelector(billingSelectors.getBillingsStats());
  const places = useSelector(placeSelectors.getPlaces());

  const updatedPlace = useSelector(placeSelectors.getPlace());
  const activePlaceStorage = placeSelectors.getActivePlaceStorage();
  const user = useSelector(userSelectors.getCurrentUser());

  const currentLang = user?.lang || localStorage.get(localStorage.Keys.LANG);

  useEffect(() => {
    if (!currentLang) {
      localStorage.set(localStorage.Keys.LANG, defaultLang);
      window.location.reload(false);
    } else if (currentLang !== localStorage.get(localStorage.Keys.LANG)) {
      localStorage.set(localStorage.Keys.LANG, currentLang);
      window.location.reload(false);
    }
  }, [currentLang]);

  useEffect(() => {
    if (activePlaceStorage?.id) {
      dispatch(reservationThunks.fetchNewReservationsCount({ placeId: activePlaceStorage.id }));
    }
  }, [activePlaceStorage?.id]); // eslint-disable-line

  useEffect(() => {
    dispatch(placeThunks.fetchPlaces());
  }, [dispatch, updatedPlace]); // eslint-disable-line

  useEffect(() => {
    if (places[0] && activePlaceStorage) {
      dispatch(placeActions.setActivePlace(places.find(p => p.id === activePlaceStorage.id)));
    }
  }, [places, activePlaceStorage, activePlace, dispatch]);

  useEffect(() => {
    if (!activePlaceStorage && places[0]) {
      dispatch(placeActions.setActivePlace(places[0]));
    }
  }, [places, activePlaceStorage, dispatch]);

  useEffect(() => {
    if (activePlace?.id) {
      dispatch(hallThunks.fetchHalls({ placeId: activePlace.id }));
      dispatch(placeThunks.fetchManagers({ placeId: activePlace.id }));
      dispatch(billingThunks.fetchBillingsStats({ placeId: activePlace.id }));
      dispatch(placeThunks.fetchCustomTags({ placeId: activePlace.id }));
      dispatch(placeThunks.fetchDeposits({ placeId: activePlace.id }));
      dispatch(dishThunks.fetchMenus({ placeId: activePlace.id, }),);
      dispatch(dishThunks.fetchDishes({ placeId: activePlace.id, }),);
    }
  }, [activePlace?.id, dispatch]) // eslint-disable-line;

  // Disable editing values in number inputs with the scroll functionality
  useEffect(() => {
    const handleWheel = (e) => {
      if (e.target.tagName.toLowerCase() === 'input' && e.target.type === 'number') {
        e.preventDefault();
      }
    };

    document.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);

  if (!activePlace || !billingsStats) {
    return <Loader />;
  }

  return children;
};

export default AppDataContainer;
