import React, { memo, useCallback, useEffect } from 'react';
import mova from 'mova';
import Box from '@mui/material/Box';
import ScrollableMenu from '@components/ScrollableMenu/ScrollableMenu';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@components/Loader/Loader';
import Description from './containers/Description/Description';
import Characteristics from './containers/Characteristics/Characteristics';
import Gallery from './containers/Gallery/Gallery';
import Hours from './containers/Hours/Hours';
import Contacts from './containers/Contacts/Contacts';
import Administration from './containers/Administration/Administration';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';
import SwitchMenu from '@components/SwitchMenu/SwitchMenu';
import styled from '@emotion/styled';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import { dictionarySelectors, dictionaryThunks } from 'state/ducks/dictionary';
import { toastrActions } from 'state/ducks/toastr';
import Integrations from './containers/Integrations/Integrations';
import Promo from './containers/Promo/Promo';
import HallManagement from './containers/HallManagement/HallManagement';
import ReservationSettings from './containers/ReservationSettings/ReservationSettings';
import BookingWidget from "./containers/BookingWidget/BookingWidget";
import { userSelectors } from "../../../state/ducks/user";
import DepositSettings from "./containers/DepositSettings/DepositSettings";

const t = mova.ns('components.Settings');

const StyledMenuWrapper = styled(Box)(({ theme }) => ({
  flexBasis: '180px',
  [theme.breakpoints.up('xl')]: {
    flexBasis: '300px',
  },
}));

const Settings = () => {
  const dispatch = useDispatch();
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const cities = useSelector(dictionarySelectors.getCities());
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('md'));
  const openHours = useSelector(placeSelectors.getPlaceOpenHours());
  const isPlaceSettingsLoading = useSelector(placeSelectors.isPlaceSettingsLoading());
  const role = useSelector(userSelectors.getUserRole());
  const isSuperAdmin = role === 'super';

  const menuItems = [
    { label: t('description'), path: 'description' },
    { label: t('characteristics'), path: 'characteristics' },
    { label: t('reservations'), path: 'reservations' },
    { label: t('promo'), path: 'promo' },
    { label: t('gallery'), path: 'gallery' },
    { label: t('hours'), path: 'hours' },
    { label: t('contacts'), path: 'contacts' },
    { label: t('administration'), path: 'administration' },
    { label: t('halls'), path: 'halls', hide: !['enterprise', 'pro'].includes(activePlace.billType) },
    { label: t('deposits'), path: 'deposits', hide: !['enterprise', 'pro'].includes(activePlace.billType) },
    { label: t('integrations'), path: 'integrations' },
  ];

  if (isSuperAdmin) {
    menuItems.push({ label: t('bookingWidget'), path: 'widget' })
  }

  const location = useLocation();
  const history = useHistory();
  const currentPath = queryString.parse(location.search).path;
  const showToastr = useCallback(message => dispatch(toastrActions.showToastr({ message })), [dispatch]);

  useEffect(() => {
    dispatch(dictionaryThunks.fetchCities());
    dispatch(dictionaryThunks.fetchCusines());
    dispatch(dictionaryThunks.fetchMoods());
    dispatch(dictionaryThunks.fetchPayments());
    dispatch(placeThunks.fetchDonations());
  }, [dispatch]);

  useEffect(() => {
    dispatch(placeThunks.fetchPhotos({ placeId: activePlace.id }));
    dispatch(placeThunks.fetchOpenHour({ placeId: activePlace.id }));
    dispatch(placeThunks.fetchContacts({ placeId: activePlace.id }));
  }, [dispatch, activePlace.id]);

  useEffect(() => {
    const urlParams = queryString.parse(location.search);

    if (!currentPath) {
      urlParams.path = 'description';
    }

    history.push(`${location.pathname}?${queryString.stringify(urlParams)}`);
  }, []); // eslint-disable-line

  if (isPlaceSettingsLoading) {
    return <Loader fullScreen />;
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography variant='h4'>{t('settings')}</Typography>
      </Box>
      {mobileView && (
        <Box mb={2}>
          <ScrollableMenu items={menuItems.filter(i => !i.hide)} />
        </Box>
      )}
      <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
        <Box flexGrow={1} minWidth={0}>
          {currentPath === 'description' && <Description showToastr={showToastr} place={activePlace} cities={cities} />}
          {currentPath === 'characteristics' && <Characteristics showToastr={showToastr} place={activePlace} />}
          {currentPath === 'gallery' && <Gallery place={activePlace} />}
          {currentPath === 'reservations' && <ReservationSettings place={activePlace} showToastr={showToastr} />}
          {currentPath === 'hours' && <Hours showToastr={showToastr} place={activePlace} openHours={openHours} />}
          {currentPath === 'contacts' && <Contacts place={activePlace} />}
          {currentPath === 'administration' && <Administration />}
          {currentPath === 'halls' && ['enterprise', 'pro'].includes(activePlace.billType) && <HallManagement />}
          {currentPath === 'deposits' && ['enterprise', 'pro'].includes(activePlace.billType) && <DepositSettings />}
          {currentPath === 'integrations' && <Integrations />}
          {currentPath === 'widget' && isSuperAdmin && <BookingWidget place={activePlace} showToastr={showToastr} />}
          {currentPath === 'promo' && <Promo place={activePlace} showToastr={showToastr} />}
        </Box>
        {!mobileView && (
          <StyledMenuWrapper flexGrow={0} flexShrink={0} ml={4}>
            <SwitchMenu items={menuItems} />
          </StyledMenuWrapper>
        )}
      </Box>
    </Box>
  );
};

export default memo(Settings);
