import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './index';
import ProtectedRoute from './ProtectedRoute';
import { useSelector } from 'react-redux';
import { userSelectors } from '@state/ducks/user';

const AppRoutes = () => {
  const role = useSelector(userSelectors.getUserRole());

  const isSuperAdmin = role === 'super';

  return (
    <Switch>
      {process.env.NODE_ENV === 'development' && <Route {...routes.sandbox} />}
      <Route {...routes.oauth} />
      <ProtectedRoute condition={isSuperAdmin} {...routes.superadmin} />
      <ProtectedRoute {...routes.home} />
      <ProtectedRoute condition={['admin', 'owner', 'super'].includes(role)} {...routes.reservations} />
      <ProtectedRoute condition={['owner', 'admin', 'super'].includes(role)} {...routes.timeline} />
      <ProtectedRoute condition={['owner', 'admin', 'super'].includes(role)} {...routes.halls} />
      <ProtectedRoute condition={['owner', 'super'].includes(role)} {...routes.settings} />
      <ProtectedRoute condition={['owner', 'analyst', 'super'].includes(role)} {...routes.channels} />
      <ProtectedRoute condition={['owner', 'admin', 'super'].includes(role)} {...routes.menu} />
      <ProtectedRoute condition={['owner', 'analyst', 'super'].includes(role)} {...routes.channelStats} />
      <ProtectedRoute condition={['owner', 'admin', 'super'].includes(role)} {...routes.clients} />
      <ProtectedRoute {...routes.profile} />
      <ProtectedRoute {...routes.reviews} />
      <ProtectedRoute condition={['owner', 'analyst', 'super'].includes(role)} {...routes.statistics} />
      <ProtectedRoute {...routes.payment} />
      <ProtectedRoute {...routes.depositStats} />
      <ProtectedRoute {...routes.newPayment} />
      <ProtectedRoute {...routes.invoiceDetails} />
      <Route {...routes.login} />
      <Route {...routes.forgot} />
      <Route {...routes.page404} />
    </Switch>
  );
};

export default AppRoutes;
