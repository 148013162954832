import React, { memo } from 'react';
import Datepicker from '@components/Datepicker/Datepicker';
import { useField } from 'formik';

const FormikDatepicker = ({ name, fullWidth, handleChange, datepickerProps, ...rest }) => {
  const [field, , { setValue }] = useField(name);

  const onChange = (val) => {
    setValue(val);
    handleChange && handleChange(val);
  };

  const defaultProps = {
    ...field,
    selected: (field.value && new Date(field.value)) || null,
    onChange,
    fullWidth,
  };

  return (
    <Datepicker datepickerProps={{ ...defaultProps, ...datepickerProps }} {...rest} />
  );
};

export default memo(FormikDatepicker);
