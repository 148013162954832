import React, { memo, useMemo } from 'react';
import Card from '@components/Card/Card';
import Box from '@mui/material/Box';
import mova from 'mova';
import Button from '@components/Button/Button';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import FormikSelect from '@form/FormikSelect';
import FormikTextInput from '@form/FormikTextInput';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import { get } from 'lodash';

const t = mova.ns('components.Promo');

const Promo = ({ place, showToastr }) => {
  const dispatch = useDispatch();
  const donations = useSelector(placeSelectors.getPlaceDonations());

  const promoSchema = useMemo(
    () =>
      Yup.object().shape({
        promo: Yup.string(),
        count: Yup.number(() => t('errors.number'))
          .min(0, t('errors.minNumber', { min: 0 }))
          .max(100, t('errors.maxNumber', { max: 100 })),
      }),
    [],
  );

  const promoItems = useMemo(
    () =>
      donations.map(d => ({
        value: d.id,
        label: d.name,
      })),
    [donations],
  );

  promoItems.unshift({ value: '', label: '' });

  const updatePromo = async values => {
    await dispatch(
      placeThunks.updatePlaceDonates({
        id: place.id,
        donates: [{ id: values.promo, count: values.count }],
      }),
    );
    showToastr(t('success'));
  };

  return (
    <Box>
      <Card>
        <Formik
          initialValues={{
            promo: get(place.donates, '[0].id') || '',
            count: get(place.donates, '[0].PlaceDonate.count') || '',
          }}
          onSubmit={updatePromo}
          validationSchema={promoSchema}
        >
          <Form>
            <Typography variant='h3' sx={{ mb: 2 }}>
              {t('title')}
            </Typography>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={12} sm={4}>
                <Typography sx={{ verticalAlign: 'middle' }}>{t('current')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormikSelect name='promo' fullWidth items={promoItems} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography sx={{ verticalAlign: 'middle' }}>{t('percent')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormikTextInput type='number' name='count' fullWidth />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button type='submit' size='large'>
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Card>
    </Box>
  );
};

export default memo(Promo);
