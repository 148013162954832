import React, { memo, useState } from 'react';
import Card from '@components/Card/Card';
import HallTable from './HallTable';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import SnapGrid from './SnapGrid';
import Icon from "../Icon/Icon";
import Switch from '@mui/material/Switch';
import { useSelector } from "react-redux";
import { placeSelectors } from "state/ducks/place";
import IconButton from "@mui/material/IconButton";
import localStorage from 'utils/localStorage';
import { getTableOffset } from "utils/utils";

const StyledInnerArea = styled(Box)(({ theme, backgroundProps, hallX, hallY }) => ({
  position: 'relative',
  zIndex: 1,
  width: hallX * 16 * 4 + 'px',
  height: hallY * 16 * 4 + 'px',
  margin: '0 auto',
  ...backgroundProps,
  border: `1px solid ${theme.palette.primary.main}`,
}));

const StyledZoomButton = styled(IconButton)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '8px',
  fontSize: 32,
  color: theme.palette.typography.primary,
  backgroundColor: '#fff',
  boxShadow: theme.shadows[1],
  '&:hover': {
    backgroundColor: theme.palette.typography.extraLight,
  }
}));

const HallsArea = ({ hall, activeElement, showGrid, assignReservation, date, showPast }) => {
  const [fullView, setFullView] = useState(false);
  const hallX = (hall.variables?.length || 16) / 2;
  const hallY = (hall.variables?.width || 16) / 2;
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const [zoom, setZoom] = useState(showGrid ? 1 : localStorage.get('hallsZoom') || 1);

  let offsetY = 0;
  const tables = (hall.spots || []).map((table) => {
    const res =
      <HallTable
        key={table.id}
        table={table}
        activeElement={activeElement}
        offsetY={offsetY}
        assignReservation={assignReservation}
        date={date}
        fullView={fullView}
        showPast={showPast}
      />;
    offsetY += getTableOffset(table);
    return res;
  });

  const backgroundProps = {
    backgroundImage: hall.bgCustom ? `url('${hall.bgCustom}')` : `url('/assets/images/patterns/${hall.variables?.bg}.jpg')`,
    backgroundSize: hall.bgCustom ? 'cover' : 'auto',
  };

  const increaseZoom = () => {
    const newZoom = zoom + 0.2;
    setZoom(newZoom);
    localStorage.set('hallsZoom', newZoom);
  }

  const decreaseZoom = () => {
    if (zoom > 0.7) {
      const newZoom = zoom - 0.2;
      setZoom(newZoom);
      localStorage.set('hallsZoom', newZoom);
    }
  }

  return (
    <Box position='relative'>
      {!showGrid && (
        <Box
          display='flex'
          flexDirection='column'
          position='absolute'
          right={8}
          top={8}
          zIndex={2}
        >
          <StyledZoomButton
            sx={{ borderBottomLeftRadius: '0 !important', borderBottomRightRadius: '0 !important', }}
            onClick={increaseZoom}
          >
            +
          </StyledZoomButton>
          <StyledZoomButton
            sx={{ borderTopLeftRadius: '0 !important', borderTopRightRadius: '0 !important' }}
            onClick={decreaseZoom}
            disabled={zoom <= 0.7}
          >
            -
          </StyledZoomButton>
        </Box>
      )}
      <Card
        id='halls-area'
        sx={{ position: 'relative', overflow: 'auto', transform: `scaleY(${zoom})`, transformOrigin: 'top' }}
      >
        <StyledInnerArea
          id='halls-area-inner'
          hallX={hallX}
          hallY={hallY}
          backgroundProps={backgroundProps}
          sx={{ transform: `scaleX(${zoom})`, transformOrigin: 'top' }}
        >
          {showGrid && <SnapGrid hallX={hallX} hallY={hallY} hall={hall} />}
          {tables}
          {!showGrid && activePlace.billTypeExtra?.includes('highload') && activePlace.showAllReservations && (
            <Card
              sx={{
                px: '4px !important',
                py: '2px !important',
                position: 'absolute',
                left: 4,
                top: -14,
                display: 'flex'
              }}
            >
              <Icon type='multiple' />
              <Switch size='small' color='secondary' checked={fullView} onChange={() => setFullView(!fullView)} />
            </Card>
          )}
        </StyledInnerArea>
      </Card>
    </Box>
  );
};

export default memo(HallsArea);
