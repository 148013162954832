import React, { memo, useCallback, useEffect } from 'react';
import Box from "@mui/material/Box";
import PeriodsSelector from "../../components/PeriodsSelector/PeriodsSelector";
import queryString from 'query-string';
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { placeSelectors } from "state/ducks/place";
import periodUtils from "../../../utils/periodUtils";
import { Table, TableBody } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import mova from "mova";
import styled from "@emotion/styled";
import Card from "../../components/Card/Card";
import Typography from "@mui/material/Typography";
import { billingSelectors, billingThunks } from '@state/ducks/billing';

const t = mova.ns('components.DepositStatistics');

const StyledTableCell = styled(TableCell)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
}));

const DepositStatistics = () => {
  const history = useHistory();
  const location = useLocation();
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const placeDeposits = useSelector(billingSelectors.getPlaceDeposits());
  const { from, to } = queryString.parse(location.search);
  const dispatch = useDispatch();

  const setDateParams = useCallback((params) => {
    const urlParams = queryString.parse(location.search);
    history.push(`${location.pathname}?${queryString.stringify({ ...urlParams, ...params })}`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activePlace.id && from && to) {
      dispatch(billingThunks.fetchPlaceDeposits({
        from, to,
        placeId: activePlace.id
      }));
    }
  }, [activePlace.id, from, to, dispatch]);

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <PeriodsSelector setDateParams={setDateParams} defaultPeriod={periodUtils.today} defaultUnit='days' />
      {Object.entries(placeDeposits).map(([day, bills]) => {
        let total = 0;
        return (
          <Card sx={{ p: 2 }} key={day}>
            <Typography sx={{ mb: 2 }} variant='h3'>{day}</Typography>
            <Table sx={{ mt: 2 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('name')}</StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('phone')}</StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('hall')}</StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('spot')}</StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('billId')}</StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('amount')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bills.map(({ id, reservation, amount }) => {
                  total = total + amount;
                  return (
                    <TableRow key={id}>
                      <StyledTableCell>{reservation.user?.displayedName}</StyledTableCell>
                      <StyledTableCell>{reservation.user?.phone}</StyledTableCell>
                      <StyledTableCell>{reservation.hall?.name}</StyledTableCell>
                      <StyledTableCell>{reservation.spots.map(s => s.label).join(', ')}</StyledTableCell>
                      <StyledTableCell>{id}</StyledTableCell>
                      <StyledTableCell>{amount} {t('uah')}</StyledTableCell>
                    </TableRow>
                  )
                })}
                <TableRow>
                  <StyledTableCell colSpan={5} sx={{ fontWeight: 'bold' }}>{t('total')}</StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold' }}>{total} {t('uah')}</StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        )
      })}
    </Box>
  );
};

export default memo(DepositStatistics);
