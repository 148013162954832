import moment from 'moment';
import theme from "../theme/theme";

export const nil = (...params) => params.some(p => p === undefined || p === null);

export const clone = (obj, propertyWhitelist = []) => {
  // propertyWhitelist allows us to specify a filter of fields we want cloned
  // but the filter is applied only to the top-level fields
  let copy;

  // Handle the simple types, and null or undefined
  if (nil(obj) || 'object' !== typeof obj) {
    return obj;
  }

  // handle Moment.js
  if (moment.isMoment(obj)) {
    return moment(obj);
  }

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // handle Promise
  if (obj instanceof Promise) {
    return obj.then();
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (let attr in obj) {
      if (
        Object.prototype.hasOwnProperty.call(obj, attr) &&
        (propertyWhitelist.length === 0 || propertyWhitelist.includes(attr))
      ) {
        copy[attr] = clone(obj[attr]);
      }
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};

export const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    timeout && clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

const reservationColorMapping = {
  other: theme.palette.reservations[0],
  business: theme.palette.reservations[1],
  date: theme.palette.reservations[2],
  family: theme.palette.reservations[3],
  friends: theme.palette.reservations[4],
  birthday: theme.palette.reservations[5],
  football: theme.palette.reservations[6],
}

export const getReservationColor = (color) => reservationColorMapping[color] || reservationColorMapping.other;

export const sortSpots = (s1, s2) => {
  if (parseInt(s1.label) && parseInt(s2.label)) {
    return parseInt(s1.label) - parseInt(s2.label)
  } else {
    return s1.label.localeCompare(s2.label)
  }
}

export const PHONE_REGEX = /^\+[0-9]{10,12}$/;

export const getTableOffset = (table) => {
  if (!table) {
    return 64;
  } else if (table.type === 'ELLIPSE') {
    return table.variables?.length * 32 || 64;
  } else if (table.type === 'RECT') {
    return table.variables?.width * 32 || 64;
  } else if (table.type === 'LOUNGE') {
    if (table.variables?.seats === 2) {
      return table.variables?.length * 4 / 3 * 32 || 64;
    } else {
      return table.variables?.length * 64 || 64;
    }
  }
}
