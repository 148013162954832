import React, { memo, useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import mova from "mova";
import Button from "../../../../components/Button/Button";
import Icon from "../../../../components/Icon/Icon";
import Card from "../../../../components/Card/Card";
import FormikTextInput from "../../../../form/FormikTextInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikCheckbox from "../../../../form/FormikCheckbox";
import { Table, TableBody } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { placeSelectors, placeThunks } from "../../../../../state/ducks/place";
import { toastrActions } from "../../../../../state/ducks/toastr";
import { clone } from "../../../../../utils/utils";
import Typography from "@mui/material/Typography";
import ConfirmModal from "../../../../components/ConfirmModal/ConfirmModal";
import useModal from "../../../../../utils/hooks/useModal/useModal";

const t = mova.ns('components.Deposits');

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const schema = Yup.object().shape({
  label: Yup.string().required(() => t('errors.required')),
  active: Yup.boolean(),
  types: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required(() => t('errors.required')),
      1: Yup.number(),
      2: Yup.number(),
      3: Yup.number(),
      4: Yup.number(),
      5: Yup.number(),
      6: Yup.number(),
      7: Yup.number(),
    })
  ),
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 'none',
  padding: theme.spacing(0.5),
}));


const DepositSettings = () => {
  const [deleteDepositModalOpen, deleteDeposit, closeDeleteDepositModal, deletedDeposit] = useModal();
  const [deleteTypeModalOpen, deleteType, closeDeleteTypeModal, deletedType] = useModal();
  const [tempDeposits, setTempDeposits] = useState([]);
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const dispatch = useDispatch();
  const deposits = useSelector(placeSelectors.getDeposits());

  useEffect(() => {
    setTempDeposits(clone(deposits));
  }, [deposits]);

  const saveDeposit = (deposit) => {
    if (deposit.types.length === 0) {
      dispatch(toastrActions.showToastr({ variant: 'error', message: t('minOneType') }));
    } else {
      dispatch(placeThunks.saveDeposit({ placeId: activePlace.id, deposit }))
        .then(() => {
          dispatch(toastrActions.showToastr({ message: t('success') }));
        });
    }
  }

  const addDeposit = () => {
    setTempDeposits([...tempDeposits, { label: '', types: [], active: false }])
  }

  const addType = (formik) => {
    const newType = {
      active: true,
      label: '',
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
    };
    formik.setFieldValue('types', [...formik.values.types, newType]);
  };

  const removeDeposit = (deposit, index) => {
    if (deposit.id) {
      deleteDeposit(deposit);
    } else {
      setTempDeposits(tempDeposits.filter((_, i) => i !== index));
    }
  }

  const confirmDeleteDeposit = () => {
    dispatch(placeThunks.deleteDeposit({ placeId: activePlace.id, depositId: deletedDeposit.id }));
    closeDeleteDepositModal();
  }

  const removeType = (deposit, type, index, formik) => {
    if (type.id) {
      deleteType({ deposit, type })
    } else {
      formik.setFieldValue('types', formik.values.types.filter((t, i) => i !== index));
    }
  }

  const confirmDeleteType = () => {
    dispatch(placeThunks.deleteDepositType({
      placeId: activePlace.id,
      depositId: deletedType.deposit.id,
      typeId: deletedType.type.id
    }));
    closeDeleteTypeModal();
  }

  return (
    <Box>
      <Button startIcon={<Icon type='plus' />} onClick={addDeposit}>{t('addDeposit')}</Button>
      <Box mt={2} display='flex' flexDirection='column' gap={2}>
        {tempDeposits.map((deposit, depositIndex) => (
          <Formik
            key={depositIndex}
            initialValues={{
              id: deposit.id,
              label: deposit.label || '',
              active: !!deposit.active,
              types: deposit.types || [],
            }}
            validationSchema={schema}
            onSubmit={(values) => saveDeposit(values, depositIndex)}
            enableReinitialize
          >
            {formik => (
              <Form>
                <Card>
                  <Box display='flex' justifyContent='space-between' alignItems='center' gap={2} flexWrap='wrap'>
                    <Box display='flex' alignItems='center' gap={2} flexWrap='wrap'>
                      <FormikCheckbox name='active' label={t('active')} />
                      <FormikTextInput name='label' placeholder={t('depositPlaceholder')} />
                    </Box>
                    <Box display='flex' alignItems='center' gap={2} flexWrap='wrap'>
                      <Button color='primary' startIcon={<Icon type='plus' />} onClick={() => addType(formik)}>
                        {t('addType')}
                      </Button>
                      <IconButton onClick={() => removeDeposit(deposit, depositIndex)}>
                        <Icon type='remove' />
                      </IconButton>
                    </Box>
                  </Box>
                  {formik.values.types.length > 0 && (
                    <Box sx={{ overflowX: 'auto', overflowY: 'hidden' }}>
                      <Table sx={{ mt: 2, tableLayout: 'fixed', width: 'unset' }}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell component='th' sx={{ minWidth: 100 }} />
                            {formik.values.types.map((type, index) => (
                              <StyledTableCell
                                key={index}
                                component='th'
                                sx={{ width: 200, minWidth: 200, position: 'relative', verticalAlign: 'top' }}
                              >
                                <FormikTextInput
                                  sx={{ width: '100%' }}
                                  InputProps={{ sx: { pr: 4 } }}
                                  name={`types[${index}].label`}
                                  placeholder={t('typePlaceholder')}
                                />
                                <IconButton
                                  sx={{ position: 'absolute', right: 8, top: 6 }}
                                  onClick={() => removeType(deposit, type, index, formik)}
                                >
                                  <Icon type='remove' />
                                </IconButton>
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <StyledTableCell />
                            <StyledTableCell colSpan='100%'>{t('amountInUah')} {t('uah')}</StyledTableCell>
                          </TableRow>
                          {['1', '2', '3', '4', '5', '6', '7'].map(day => (
                            <TableRow key={day}>
                              <StyledTableCell>{t(`days.${WEEKDAYS[day - 1]}`)}</StyledTableCell>
                              {formik.values.types.map((type, index) => (
                                <StyledTableCell key={index}>
                                  <FormikTextInput name={`types[${index}].${day}`} placeholder={t('amountInUahPlaceholder')} />
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  )}
                  <Box mt={2} display='flex' justifyContent='center'>
                    <Button type='submit'>{t('save')}</Button>
                  </Box>
                </Card>
              </Form>
            )}
          </Formik>
        ))}
        {/* toDO: Implement calendar with exceptions */}
        {/*<Card>*/}
        {/*  Calendar will be here*/}
        {/*</Card>*/}
        <ConfirmModal
          open={deleteDepositModalOpen}
          onClose={closeDeleteDepositModal}
          confirm={confirmDeleteDeposit}
          title={t('deletingDeposit')}
          content={<Typography>{t('confirmDeleteDeposit')}</Typography>}
        />
        <ConfirmModal
          open={deleteTypeModalOpen}
          onClose={closeDeleteTypeModal}
          confirm={confirmDeleteType}
          title={t('deletingType')}
          content={<Typography>{t('confirmDeleteType')}</Typography>}
        />
      </Box>
    </Box>
  );
};

export default memo(DepositSettings);
