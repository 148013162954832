import { createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

import api from 'services/apiService';

const fetchBillings = createAsyncThunk('billing/fetchBillings', ({ placeId, ...query }) => {
  return api.get(`/places/${placeId}/billings?${queryString.stringify(query)}`);
});
const fetchBilling = createAsyncThunk('billing/fetchBilling', ({ placeId, id }) => {
  return api.get(`/places/${placeId}/billings/${id}`);
});

const addBilling = createAsyncThunk('billing/addBilling', data => {
  return api.post(`/places/${data.placeId}/billings`, data);
});

const fetchBillingsStats = createAsyncThunk('billing/fetchBillingsStats', data => {
  return api.get(`/places/${data.placeId}/billings/stats`);
});

const fetchPendingInvoices = createAsyncThunk('billing/fetchPendingInvoices', ({ placeId, ...query }) => {
  return api.get(`/places/${placeId}/billings/invoices?${queryString.stringify(query)}`);
});

const updatePendingInvoice = createAsyncThunk('billing/updatePendingInvoice', ({ placeId, type, id, ...data }) => {
  return api.put(`/places/${placeId}/billings/${id}/invoices?${queryString.stringify({ type })}`, data);
});
const updatePendingInvoicesStatus = createAsyncThunk('billing/updatePendingInvoice', ({ placeId, type, status, ...data }) => {
  return api.post(`/places/${placeId}/billings/invoices?${queryString.stringify({ type, status })}`, data);
});

const fetchPlaceDeposits = createAsyncThunk('billing/fetchPlaceDeposits', (data) => {
  return api.post(`/billings/deposits`, data);
});

export default {
  fetchBilling,
  fetchBillings,
  fetchBillingsStats,
  addBilling,
  fetchPendingInvoices,
  updatePendingInvoice,
  updatePendingInvoicesStatus,
  fetchPlaceDeposits,
};
