import React, { memo, useCallback, useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import interact from 'interactjs';
import { hallSelectors, hallThunks } from 'state/ducks/hall';
import { placeSelectors } from 'state/ducks/place';
import HallsSidebar from './components/HallsSidebar';
import Grid from '@mui/material/Grid';
import HallsArea from '@components/Halls/HallsArea';
import { getTableOffset } from "utils/utils";

const HallManagement = () => {
  const dispatch = useDispatch();
  const activeHall = useSelector(hallSelectors.getActiveHall());
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('md'), { noSsr: true });

  const [activeElement, setActiveElement] = useState(null);

  const handleAddSpot = useCallback(async () => {
    await dispatch(
      hallThunks.addSpot({
        placeId: activePlace.id,
        hallId: activeHall?.id,
        label: `#${activeHall.spots.length + 1}`,
        seats: 8,
        type: 'RECT',
        x: 16,
        y: 16,
        variables: {
          length: 2,
          width: 2
        }
      }),
    );
  }, [dispatch, activeHall, activePlace.id]);

  const handleDeleteSpot = useCallback(async () => {
    setActiveElement(null);
    await dispatch(
      hallThunks.deleteSpot({
        placeId: activePlace.id,
        hallId: activeHall?.id,
        id: activeElement?.id,
      }),
    );
  }, [dispatch, activeElement?.id, activeHall?.id, activePlace.id]);

  const saveLayout = useCallback((_, updatedSpot) => {
    let offsetY = 0;
    const tableMap = Array.from(document.getElementsByClassName('hall-table'))
      .map(table => {
        const updatedTable = {
          id: +table.id,
          x: Math.round(table.dataset.x),
          y: Math.round(+table.dataset.y + offsetY),
        };
        offsetY += getTableOffset(activeHall.spots.find(s => s.id === +table.id));
        return updatedTable;
      })
      .reduce((accum, value) => {
        accum[value.id] = value;
        return accum;
      }, {});
    const updatedHall = {
      ...activeHall,
      spots: activeHall.spots.map(s => {
        if (updatedSpot && s.id === updatedSpot.id) {
          const res = {
            ...updatedSpot,
            x: tableMap[s.id].x,
            y: tableMap[s.id].y,
          };

          if (res.type !== 'RECT') {
            res.variables.width = res.variables.length;
          }
          return res;
        } else {
          return {
            ...s,
            x: tableMap[s.id].x,
            y: tableMap[s.id].y,
          };
        }
      })
    };

    dispatch(hallThunks.updateHall({ data: updatedHall, showNotification: Boolean(updatedSpot) }));
  }, [activeHall, dispatch]);

  const dragMoveListener = (event) => {
    const target = event.target;
    const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
    const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
    target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
    target.setAttribute('data-x', x);
    target.setAttribute('data-y', y);
  };

  useEffect(() => {
    const hallsArea = document.getElementById('halls-area-inner');

    if (activeHall) {
      interact('.hall-table')
        .draggable({
          modifiers: [
            interact.modifiers.restrictRect({
              restriction: 'parent',
            }),
            interact.modifiers.snap({
              targets: [
                interact.snappers.grid({ x: 16, y: 16 }),
              ],
              range: Infinity,
              relativePoints: [{ x: 0, y: 0 }],
            }),
            interact.modifiers.restrict({
              restriction: hallsArea,
              elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
            })
          ],
          listeners: {
            move: dragMoveListener,
            end: saveLayout,
          }
        })
        .origin('parent')
        .on('tap', (e) => setActiveElement(activeHall.spots.find(s => s.id === +e.target.id)));
    }
  }, [activeHall, saveLayout]);

  useEffect(() => {
    return () => {
      interact('.hall-table').unset();
    };
  }, []);

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          xl={3}
          sx={{ flexShrink: 0, flexGrow: 0, flexBasis: mobileView ? '100%' : '250px !important' }}
        >
          <HallsSidebar
            activeElement={activeElement}
            setActiveElement={setActiveElement}
            handleSave={(spot) => saveLayout(null, spot)}
            handleAddSpot={handleAddSpot}
            handleDeleteSpot={handleDeleteSpot}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={8} xl={9} sx={{ minWidth: 0, flexShrink: 1 }}>
          {activeHall && <HallsArea hall={activeHall} activeElement={activeElement} showGrid />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(HallManagement);
