import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import styled from '@emotion/styled';
import Icon from '../Icon/Icon';
import Logo from '../Logo/Logo';
import Sidebar from '../Sidebar/Sidebar';
import { userThunks } from 'state/ducks/user';
import usePushNotifications from '@hooks/usePushNotifications/usePushNotifications';
import moment from 'moment';
import userSelectors from '@state/ducks/user/selectors';
import localStorage from 'utils/localStorage';
import 'moment/locale/ru';
import 'moment/locale/uk';
import jwtService from 'services/jwtService';
import { Link } from 'react-router-dom';
import { reservationSelectors } from 'state/ducks/reservation';
import { useTheme } from '@mui/material';
import { keyframes } from '@mui/system';

const swing = keyframes`
  0% {
    transform: rotateZ(0);
}
  5% {
    transform: rotateZ(15deg);
}
  10% {
    transform: rotateZ(-12.5deg);
}
  15% {
    transform: rotateZ(5deg);
}
  20% {
    transform: rotateZ(0);
}
`;

const StyledBellIcon = styled(Icon)(({ animated }) => ({
  animation: animated ? `${swing} 3s infinite ease` : 'none'
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: theme.spacing(9),
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: theme.spacing(9),
}));

const locales = {
  ua: 'uk',
  ru: 'ru',
  en: 'en',
  es: 'es',
};

moment.updateLocale('en', {
  week: {
    dow: 1,
    doy: 4
  }
});

function Header() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const newReservations = useSelector(reservationSelectors.getNewReservations());
  const user = useSelector(userSelectors.getCurrentUser());

  const role = useSelector(userSelectors.getUserRole());
  const isSuperAdmin = role === 'super';

  const { enableNotifications } = usePushNotifications();

  useEffect(() => {
    const currentLang = user?.lang || localStorage.get(localStorage.Keys.LANG);
    moment.locale(locales[currentLang]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    enableNotifications();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(userThunks.getNotices());
  }, [dispatch]);

  const toggleSidebar = newVal => {
    if (newVal && !newVal.target) {
      // go to else for Event object
      setSidebarOpen(newVal);
    } else {
      setSidebarOpen(open => !open);
    }
  };

  const logout = () => {
    jwtService.logout();
  };

  return (
    <StyledAppBar position='sticky'>
      <StyledToolbar>
        <IconButton onClick={toggleSidebar}>
          <Icon type='menu' />
        </IconButton>
        <Logo />
        {isSuperAdmin && (
          <IconButton component={Link} to='/superadmin?path=places'>
            <Icon type='star' />
          </IconButton>
        )}
        <IconButton component={Link} to='/reservations?filter=REQUESTED'>
          <StyledBellIcon
            type='bell'
            color={newReservations > 0 ? theme.palette.typography.secondary : theme.palette.common.white}
            filled={newReservations > 0}
            animated={newReservations > 0}
          />
        </IconButton>
        <IconButton onClick={logout}>
          <Icon type='logout' color='white' />
        </IconButton>
        <SwipeableDrawer
          anchor='left'
          open={sidebarOpen}
          onClose={() => toggleSidebar(false)}
          onOpen={() => toggleSidebar(true)}
        >
          <Sidebar close={() => setSidebarOpen(false)} />
        </SwipeableDrawer>
      </StyledToolbar>
    </StyledAppBar>
  );
}

export default memo(Header);
