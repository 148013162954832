import { createTheme } from '@mui/material';

const redMain = '#EB233F';
const redLight = '#fa2241';
const redDark = '#cd1f34';
const blackMain = '#333333';
const spacing = 8;

const customBreakpointValues = {
  values: {
    xs: 0,
    xssm: 460,
    sm: 600,
    smmd: 750,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

export default createTheme({
  breakpoints: {
    ...customBreakpointValues,
  },
  palette: {
    primary: {
      main: blackMain,
      light: '#585858',
      dark: '#222222',
      contrastText: '#FFF'
    },
    secondary: {
      main: redMain,
      light: redLight,
      dark: redDark,
      background: '#FFD9D9',
      contrastText: '#FFF'
    },
    info: {
      main: 'white',
      light: 'white',
      dark: 'white',
      contrastText: blackMain
    },
    success: {
      main: '#04A3E3',
      light: '#04b7f5',
      dark: '#048ec3',
      contrastText: blackMain
    },
    typography: {
      primary: blackMain,
      secondary: redMain,
      blurred: '#585858',
      info: '#868686',
      light: '#B4B4B4',
      extraLight: '#D9D9D9',
      background: '#f8f8f8'
    },
    error: {
      main: redDark,
      light: redLight,
      dark: redDark,
      contrastText: '#FFF'
    },
    reservations: ['#315E0E', '#0CB7CF', '#E619F6', '#EB0C29', '#F5AB0F', '#367CFF', '#13A866', '#F56D02', '#1651C2', '#B40FF5'],
  },
  spacing: spacing,
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    stat: {
      fontSize: 32,
      fontWeight: 600
    },
    h0: {
      fontSize: 28,
      fontWeight: 600
    },
    h1: {
      fontSize: 22,
      fontWeight: 600
    },
    h2: {
      fontSize: 18,
      fontWeight: 600
    },
    h3: {
      fontSize: 16,
      fontWeight: 600
    },
    h4: {
      fontSize: 14,
      fontWeight: 600
    },
    h5: {
      fontSize: 12,
      fontWeight: 600
    },
    body0: {
      fontSize: 18,
      fontWeight: 400
    },
    body1: {
      fontSize: 16,
      fontWeight: 400
    },
    body2: {
      fontSize: 14,
      fontWeight: 400
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.2
    },
  },
  shape: {
    borderRadiusSmall: spacing,
    borderRadius: spacing * 2,
    borderRadiusBigger: spacing * 3
  },
  shadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: spacing
      }
    }
  }
});
