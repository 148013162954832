const getBillings = () => state => state.billing.list.data;
const getBillingsLoading = () => state => state.billing.list.isLoading;
const getBillingsError = () => state => state.billing.list.error;

const getBilling = () => state => state.billing.details.data;
const getBillingLoading = () => state => state.billing.details.isLoading;
const getBillingError = () => state => state.billing.details.error;

const getNewBill = () => state => state.billing.newBill.data;
const getNewBillLoading = () => state => state.billing.newBill.isLoading;
const getNewBillError = () => state => state.billing.newBill.error;

const getBillingsStats = () => state => state.billing.stats.data;
const getBillingsStatsLoading = () => state => state.billing.stats.isLoading;
const getBillingsStatsError = () => state => state.billing.stats.error;

const getPendingInvoices = () => state => state.billing.listInvoices.data;
const getPendingInvoicesLoading = () => state => state.billing.listInvoices.isLoading;
const getPendingInvoicesError = () => state => state.billing.listInvoices.error;

const getPlaceDeposits = () => state => state.billing.placeDeposits.data;
const getPlaceDepositsLoading = () => state => state.billing.placeDeposits.isLoading;
const getPlaceDepositsError = () => state => state.billing.placeDeposits.error;

export default {
  getBilling,
  getBillingLoading,
  getBillingError,

  getBillings,
  getBillingsLoading,
  getBillingsError,

  getNewBill,
  getNewBillLoading,
  getNewBillError,

  getBillingsStats,
  getBillingsStatsLoading,
  getBillingsStatsError,

  getPendingInvoices,
  getPendingInvoicesLoading,
  getPendingInvoicesError,

  getPlaceDeposits,
  getPlaceDepositsLoading,
  getPlaceDepositsError,
};
